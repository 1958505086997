import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotationPage = () => (
  <Layout>
    <SEO title="特定商取引法に基づく表記" />
    <h1>特定商取引法に基づく表記</h1>
    <p>
      「特定商取引に関する法律」第11条（通信販売についての広告）に基づき以下に明示いたします。
    </p>
    <h2>事業者の名称</h2>
    <p>株式会社クリモ</p>
    <h2>運営責任者</h2>
    <p>渡邊達明</p>
    <h2>住所</h2>
    <p>〒105‐0013 東京都港区浜松町2丁目2番15号 浜松町ダイヤビル2F</p>
    <h2>連絡先</h2>
    <p>+819037547373</p>
    <p>※この電話番号からのご利用方法お問い合わせや営業はお受けできません。</p>
    <p>apps＠cremo.tokyo</p>
    <h2>役務の提供の時期</h2>
    <p>登録後すぐにご利用可能です。</p>
    <h2>支払いの時期及び方法</h2>
    <p>有料アプリケーションの購入の場合は購入時。</p>
    <p>
      定期契約の場合は毎月1日に、前月分の利用状況に応じてクレジットカードによるお支払いとなります。各クレジットカード会社の定めるお支払時期に支払っていただきます。
    </p>
    <h2>商品代金以外の必要料金</h2>
    <p>ありません。</p>
    <h2>返品について </h2>
    <p>商品の性質上、お客様都合による返品はご容赦下さい。</p>
  </Layout>
)

export default NotationPage
